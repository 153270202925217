<template>
  <base-file-upload
    :on-file-change="onFileChange"
    :text="$t('COMMON.IMPORT_EXTERNAL_BRANDS')"
    :disabled="isLoading || isDisabled"
    :is-uploading="isUploading"
    :error-message="errorMessage"
    id="import-external-brands"
    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
  />
</template>

<script>
import BaseFileUpload from "@/components/BaseFileUpload";
import { mapActions, mapMutations } from "vuex";
export default {
  name: "ImportExternalBrands",
  components: { BaseFileUpload },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isUploading: false,
      errorMessage: null,
      clearFileInputTimeout: null,
      isDisabled: false
    };
  },
  beforeDestroy() {
    this.errorMessageTimeout = null;
  },
  methods: {
    ...mapMutations({
      setExternalBrands: "baseConfiguration/setExternalBrands"
    }),
    ...mapActions({
      uploadBrands: "api/draft/uploadBrands"
    }),
    onFileChange(file) {
      this.errorMessage = null;
      this.setExternalBrands({ brands: [], file: null });

      if (!file) {
        return;
      }
      const formData = new FormData();
      formData.append("brands", file);
      this.isUploading = true;

      this.uploadBrands(formData)
        .then(({ data }) => {
          this.$notifier.notify({
            message: "File has been imported successfully!"
          });
          this.setExternalBrands(data);
        })
        .catch(error => {
          this.setErrorMessage(error.response.data.error);
        })
        .finally(() => {
          this.isUploading = false;
        });
    },
    setErrorMessage(error) {
      this.errorMessage = error;
      this.$notifier.notify({
        message: error,
        color: "error"
      });
      const clearFileInput = () => {
        this.errorMessage = null;
        this.setExternalBrands({ brands: [], file: null });
        this.$root.$emit("import-external-brands-reset-base-file-input");
      };
      this.clearFileInputTimeout = setTimeout(clearFileInput, 1000 * 2);
    }
  }
};
</script>
