<template>
  <configuration-list
    :items="topics"
    :disable-add-new="currentlyEditedTopic !== null"
    :show-edit-icon="showEditIcon"
    :is-item-dark="isItemDark"
    :add-button-label="$t('PROJECTS.FORM.ADD_NEW_TOPIC')"
    @add="handleAddTopic"
    @edit="handleEditTopic"
  />
</template>

<script>
import ConfigurationList from "@/views/Project/components/ConfigurationList";
import { mapMutations, mapState } from "vuex";
import { topicDataFactory } from "@/store/models/baseConfiguration";
export default {
  name: "TopicList",
  components: { ConfigurationList },
  computed: {
    ...mapState({
      topics: state => state.baseConfiguration.topics,
      currentlyEditedTopic: state =>
        state.baseConfiguration.currentlyEditedTopic
    })
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === "baseConfiguration/removeTopic") {
        this.removeTopicFromBrands(mutation.payload);
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    ...mapMutations({
      addTopic: "baseConfiguration/addTopic",
      removeTopicFromBrands: "baseConfiguration/removeTopicFromBrands",
      setCurrentlyEditedTopic: "baseConfiguration/setCurrentlyEditedTopic"
    }),
    handleAddTopic() {
      if (this.currentlyEditedTopic === null) {
        const topic = topicDataFactory();
        this.addTopic(topic);
        this.setCurrentlyEditedTopic(topic);
      }
    },
    handleEditTopic(topic) {
      this.setCurrentlyEditedTopic(topic);
    },
    showEditIcon() {
      return this.currentlyEditedTopic === null;
    },
    isItemDark(brand) {
      return (
        this.currentlyEditedTopic &&
        this.currentlyEditedTopic.localId === brand.localId
      );
    }
  }
};
</script>
