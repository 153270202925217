import i18n from "@/i18n";

export const cantBeSpacesOnly = value => {
  if (value) {
    return (
      value.trim().length > 0 ||
      i18n.t("PROJECT.FORM.VALIDATION.NO_SPACES_ONLY")
    );
  }
  return true;
};

export const canBeDigitsOnly = (event, input) => {
  const { keyCode } = event;
  if (keyCode === 46) {
    return input && input.indexOf(".") === -1;
  } else {
    if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) return false;
  }
  return true;
};

export const isValueNaN = value => {
  return Number.isNaN(Number(value)) || Number.isNaN(value) || value === "NaN";
};
