var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"1","align-self":"center"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-facebook")])],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('COMMON.COMMENTS'),"filled":"","hide-details":""},on:{"blur":function($event){_vm.data.comments.fb = _vm.data.comments.fb
            ? parseFloat(_vm.data.comments.fb)
            : _vm.data.comments.fb}},model:{value:(_vm.data.comments.fb),callback:function ($$v) {_vm.$set(_vm.data.comments, "fb", $$v)},expression:"data.comments.fb"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('COMMON.LIKES'),"filled":"","hide-details":""},on:{"blur":function($event){_vm.data.likes.fb = _vm.data.likes.fb
            ? parseFloat(_vm.data.likes.fb)
            : _vm.data.likes.fb}},model:{value:(_vm.data.likes.fb),callback:function ($$v) {_vm.$set(_vm.data.likes, "fb", $$v)},expression:"data.likes.fb"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('COMMON.SHARES'),"filled":"","hide-details":""},on:{"blur":function($event){_vm.data.shares.fb = _vm.data.shares.fb
            ? parseFloat(_vm.data.shares.fb)
            : _vm.data.shares.fb}},model:{value:(_vm.data.shares.fb),callback:function ($$v) {_vm.$set(_vm.data.shares, "fb", $$v)},expression:"data.shares.fb"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1","align-self":"center"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-twitter")])],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('COMMON.COMMENTS'),"filled":"","hide-details":""},on:{"blur":function($event){_vm.data.comments.twitter = _vm.data.comments.twitter
            ? parseFloat(_vm.data.comments.twitter)
            : _vm.data.comments.twitter}},model:{value:(_vm.data.comments.twitter),callback:function ($$v) {_vm.$set(_vm.data.comments, "twitter", $$v)},expression:"data.comments.twitter"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('COMMON.LIKES'),"filled":"","hide-details":""},on:{"blur":function($event){_vm.data.likes.twitter = _vm.data.likes.twitter
            ? parseFloat(_vm.data.likes.twitter)
            : _vm.data.likes.twitter}},model:{value:(_vm.data.likes.twitter),callback:function ($$v) {_vm.$set(_vm.data.likes, "twitter", $$v)},expression:"data.likes.twitter"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('COMMON.SHARES'),"filled":"","hide-details":""},on:{"blur":function($event){_vm.data.shares.twitter = _vm.data.shares.twitter
            ? parseFloat(_vm.data.shares.twitter)
            : _vm.data.shares.twitter}},model:{value:(_vm.data.shares.twitter),callback:function ($$v) {_vm.$set(_vm.data.shares, "twitter", $$v)},expression:"data.shares.twitter"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1","align-self":"center"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-instagram")])],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('COMMON.COMMENTS'),"filled":"","hide-details":""},on:{"blur":function($event){_vm.data.comments.instagram = _vm.data.comments.instagram
            ? parseFloat(_vm.data.comments.instagram)
            : _vm.data.comments.instagram}},model:{value:(_vm.data.comments.instagram),callback:function ($$v) {_vm.$set(_vm.data.comments, "instagram", $$v)},expression:"data.comments.instagram"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('COMMON.LIKES'),"filled":"","hide-details":""},on:{"blur":function($event){_vm.data.likes.instagram = _vm.data.likes.instagram
            ? parseFloat(_vm.data.likes.instagram)
            : _vm.data.likes.instagram}},model:{value:(_vm.data.likes.instagram),callback:function ($$v) {_vm.$set(_vm.data.likes, "instagram", $$v)},expression:"data.likes.instagram"}})],1),_c('v-col')],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1","align-self":"center"}}),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('COMMON.VIDEO'),"filled":"","hide-details":""},on:{"blur":function($event){_vm.data.sum.video = _vm.data.sum.video
            ? parseFloat(_vm.data.sum.video)
            : _vm.data.sum.video}},model:{value:(_vm.data.sum.video),callback:function ($$v) {_vm.$set(_vm.data.sum, "video", $$v)},expression:"data.sum.video"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('COMMON.PHOTO'),"filled":"","hide-details":""},on:{"blur":function($event){_vm.data.sum.photo = _vm.data.sum.photo
            ? parseFloat(_vm.data.sum.photo)
            : _vm.data.sum.photo}},model:{value:(_vm.data.sum.photo),callback:function ($$v) {_vm.$set(_vm.data.sum, "photo", $$v)},expression:"data.sum.photo"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('COMMON.TEXT'),"filled":"","hide-details":""},on:{"blur":function($event){_vm.data.sum.text = _vm.data.sum.text
            ? parseFloat(_vm.data.sum.text)
            : _vm.data.sum.text}},model:{value:(_vm.data.sum.text),callback:function ($$v) {_vm.$set(_vm.data.sum, "text", $$v)},expression:"data.sum.text"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }