<template>
  <configuration-list
    :items="brands"
    :show-edit-icon="() => false"
    :is-item-dark="() => false"
    hide-hover
    hide-add-button
  />
</template>

<script>
import { mapState } from "vuex";
import ConfigurationList from "@/views/Project/components/ConfigurationList";

export default {
  name: "ExternalBrandsList",
  components: { ConfigurationList },
  computed: {
    ...mapState({
      externalBrands: state => state.baseConfiguration.externalBrands
    }),
    brands() {
      return this.externalBrands.brands.map(brand => ({
        name: brand
      }));
    }
  }
};
</script>
