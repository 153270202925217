<template>
  <v-form ref="form">
    <v-card flat dark class="pa-3">
      <v-tabs v-model="tab" class="bc-data-sources-tabs">
        <v-tabs-slider color="action"></v-tabs-slider>

        <v-tab>
          Data settings
        </v-tab>
        <v-tab v-if="areTopicsSelected">
          Brand topics
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="pa-3" dark>
        <v-tab-item eager>
          <data-settings-form ref="dataSettingsForm" />
        </v-tab-item>
        <v-tab-item v-if="areTopicsSelected" eager>
          <brand-topics-form ref="brandTopicsForm" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-row>
      <v-spacer />
      <v-col class="text-right">
        <v-btn
          class="mr-2"
          color="error"
          outlined
          :disabled="disableDelete"
          @click="handleDelete"
        >
          {{ $t("COMMON.DELETE") }}
        </v-btn>
        <v-btn class="mr-2" outlined @click="handleCancel">{{
          $t("COMMON.CANCEL")
        }}</v-btn>
        <v-btn outlined color="success" @click="handleSubmit">{{
          saveActionButtonText
        }}</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import DataSettingsForm from "@/views/Project/components/Forms/BaseConfiguration/DataSetttingsForm";
import BrandTopicsForm from "@/views/Project/components/Forms/BaseConfiguration/BrandTopicsForm";

export default {
  name: "BrandForm",
  components: { BrandTopicsForm, DataSettingsForm },
  data() {
    return {
      tab: null,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      topics: state => state.baseConfiguration.topics
    }),
    ...mapGetters({
      currentlyEditedBrand: "baseConfiguration/getCurrentlyEditedBrand",
      selectedDataSources: "baseConfiguration/getSelectedDataSources"
    }),
    areTopicsSelected() {
      return this.selectedDataSources.includes("TOPICS_FROM_LANGUAGE_AUDIT");
    },
    disableDelete() {
      return !this.currentlyEditedBrand.wasSaved;
    },
    saveActionButtonText() {
      return this.currentlyEditedBrand.wasSaved
        ? this.$t("COMMON.UPDATE")
        : this.$t("COMMON.ADD");
    }
  },
  mounted() {
    this.setBrandFormRef(this.$refs.form);
  },
  beforeDestroy() {
    this.handleCancel();
  },
  methods: {
    ...mapMutations({
      setBrandFormRef: "baseConfiguration/setBrandFormRef",
      removeBrand: "baseConfiguration/removeBrand",
      updateBrand: "baseConfiguration/updateBrand",
      setCurrentlyEditedBrand: "baseConfiguration/setCurrentlyEditedBrand"
    }),
    handleSubmit() {
      if (!this.$refs.form.validate()) {
        const hasFirstTabError =
          !this.$refs.dataSettingsForm.form.name ||
          !this.$refs.dataSettingsForm.form.tagId ||
          (!this.$refs.dataSettingsForm.form.bavOffline &&
            this.selectedDataSources.includes("BAV_OFFLINE"));
        const { form } = this.$refs.brandTopicsForm
          ? this.$refs.brandTopicsForm
          : { form: {} };
        const hasSecondTabError =
          Object.keys(form).length < this.topics.length ||
          Object.keys(form).filter(key => !form[key]).length > 0 ||
          Object.keys(form) < 1;

        if (this.tab === 1 && hasFirstTabError && !hasSecondTabError) {
          this.tab = 0;
        }
        if (this.tab === 0 && !hasFirstTabError && hasSecondTabError) {
          this.tab = 1;
        }
        return;
      }
      this.$root.$emit("updatePulsar");
      const data = {
        ...this.$refs.dataSettingsForm.form,
        wasSaved: true
      };
      if (this.areTopicsSelected && this.$refs.brandTopicsForm) {
        data.topics = { ...this.$refs.brandTopicsForm.form };
      }
      delete data.pulsar;
      this.updateBrand(data);

      this.clearCurrentlyEditedBrand();
    },
    handleCancel() {
      if (this.currentlyEditedBrand && !this.currentlyEditedBrand.wasSaved) {
        this.removeBrand(this.currentlyEditedBrand);
      }

      this.clearCurrentlyEditedBrand();
    },
    handleDelete() {
      this.removeBrand(this.currentlyEditedBrand);
      this.clearCurrentlyEditedBrand();
    },
    clearCurrentlyEditedBrand() {
      this.setCurrentlyEditedBrand(null);
    }
  }
};
</script>
