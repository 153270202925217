<template>
  <v-card :disabled="disabled" :loading="isLoading" flat class="pa-5 mx-auto">
    <v-card-text>
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PageCard",
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
