<template>
  <div>
    <section-heading :sub-text="$t('PROJECTS.FORM.DATA_SOURCES')" />
    <v-checkbox
      v-for="(text, source) in dataSources"
      :key="source"
      class="d-inline-block mr-4"
      v-model="selected"
      :label="text"
      :value="source"
      :disabled="isEditing"
      color="primary"
      @change="handleSelect"
    />
    <v-row>
      <v-col class="py-0">
        <v-img
          src="@/assets/images/brandwatch-logo-horizontal.png"
          max-width="125"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="brandwatchQueryString"
          :rules="[
            v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED'),
            rules.brandwatchQueryString
          ]"
          :disabled="isLoading"
          filled
          :label="$t('PROJECTS.FORM.BRANDWATCH_QUERY')"
          persistent-hint
          validate-on-blur
          :hint="
            `${$t('PROJECT.FORM.BRANDWATCH_QUERY_INFO')}${$t(
              'COMMON.EXAMPLE'
            )}: app.brandwatch.com/project/1998283878/explore/2000280218`
          "
          @blur="handleSaveBrandwatchQueryString"
        >
          <template v-slot:message="{ message, key }">
            <div style="line-height: 18px" v-html="message" :key="key"></div>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SectionHeading from "@/components/SectionHeading";
import { dataSources } from "@/store/modules/baseConfiguration";
import { mapGetters, mapMutations, mapState } from "vuex";
import { stateFactory } from "@/store/models/baseConfiguration";

export default {
  name: "ProjectDataSources",
  components: { SectionHeading },
  data() {
    return {
      dataSources,
      selected: [],
      brandwatchQueryString: null,
      isLoading: false,
      rules: {
        brandwatchQueryString: value => {
          const pattern = /app.brandwatch.com\/project\/\d+\/explore\/\d+/;
          return (
            pattern.test(value) ||
            this.$t(
              "PROJECT.FORM.VALIDATION.BRANDWATCH_QUERY_STRING_FORMAT_ERROR"
            )
          );
        }
      }
    };
  },
  computed: {
    ...mapState({
      savedBrandwatchQueryString: state =>
        state.baseConfiguration.brandwatchQueryString,
      currentDraftData: state => state.baseConfiguration.currentDraftData,
      currentlyEditedBrand: state =>
        state.baseConfiguration.currentlyEditedBrand,
      currentlyEditedTopic: state =>
        state.baseConfiguration.currentlyEditedTopic
    }),
    ...mapGetters({
      getSelectedDataSources: "baseConfiguration/getSelectedDataSources",
      brands: "baseConfiguration/getBrands",
      isEditing: "baseConfiguration/isEditing"
    })
  },
  watch: {
    getSelectedDataSources: {
      immediate: true,
      handler() {
        this.selected = [...this.getSelectedDataSources];
        if (this.currentlyEditedBrand && !this.currentlyEditedBrand.wasSaved) {
          this.removeBrand(this.currentlyEditedBrand);
        }
        if (this.currentlyEditedTopic && !this.currentlyEditedTopic.wasSaved) {
          this.removeTopic(this.currentlyEditedTopic);
        }
        this.setCurrentlyEditedBrand(null);
        this.setCurrentlyEditedTopic(null);
      }
    },
    currentDraftData: {
      immediate: true,
      handler() {
        if (this.currentDraftData) {
          this.brandwatchQueryString = this.currentDraftData.draft.brandwatchQueryString;
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      removeTopicsFromBrands: "baseConfiguration/removeTopicsFromBrands",
      removeBavOffline: "baseConfiguration/removeBavOffline",
      setSelectedDataSources: "baseConfiguration/setSelectedDataSources",
      setTopics: "baseConfiguration/setTopics",
      setExternalBrands: "baseConfiguration/setExternalBrands",
      setBrandwatchQueryString: "baseConfiguration/setBrandwatchQueryString",
      setCurrentlyEditedBrand: "baseConfiguration/setCurrentlyEditedBrand",
      setCurrentlyEditedTopic: "baseConfiguration/setCurrentlyEditedTopic",
      removeBrand: "baseConfiguration/removeBrand",
      removeTopic: "baseConfiguration/removeTopic"
    }),
    handleSelect() {
      if (!this.selected.includes("TOPICS_FROM_LANGUAGE_AUDIT")) {
        this.setTopics([]);
        this.removeTopicsFromBrands();
      }
      if (!this.selected.includes("BAV_OFFLINE")) {
        this.removeBavOffline();
      }
      if (!this.selected.includes("EXTERNAL_BRANDS")) {
        this.setExternalBrands(stateFactory().externalBrands);
      }

      this.setSelectedDataSources(this.selected);
    },
    handleSaveBrandwatchQueryString() {
      const isStringValid =
        this.rules.brandwatchQueryString(this.brandwatchQueryString) === true;

      if (isStringValid) {
        if (
          this.brands.length > 0 &&
          this.brandwatchQueryString !== this.savedBrandwatchQueryString &&
          this.savedBrandwatchQueryString !== null
        ) {
          this.$modals.confirmation({
            action: this.setBrandwatchQueryString.bind(
              this,
              this.brandwatchQueryString
            ),
            cancelAction: () => {
              this.brandwatchQueryString = this.savedBrandwatchQueryString.slice();
            },
            content: this.$t(
              "PROJECT.FORM.CONFIRM_BRANDWATCH_QUERY_STRING_CHANGE"
            )
          });
        } else {
          this.setBrandwatchQueryString(this.brandwatchQueryString);
        }
      } else {
        this.setBrandwatchQueryString(null);
      }
    }
  }
};
</script>
