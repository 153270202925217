<template>
  <div>
    <div class="body-1">{{ text }}</div>
    <div class="caption">{{ subText }}</div>
  </div>
</template>

<script>
export default {
  name: "SectionHeading",
  props: {
    text: {
      type: String,
      default: ""
    },
    subText: {
      type: String,
      default: ""
    }
  }
};
</script>
