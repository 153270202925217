<template>
  <div>
    <v-row dense v-for="topic in topics" :key="topic.localId">
      <v-col>
        <v-text-field
          v-model="form[topic.localId]"
          :label="topic.name"
          :rules="[v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED')]"
          type="number"
          filled
          @blur="form[topic.localId] = parseFloat(form[topic.localId])"
        />
      </v-col>
    </v-row>
    <v-row v-if="topics.length < 1" dense>
      <v-col class="text-center pa-4">
        {{ $t("COMMON.NOTHING_ADDED") }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BrandTopicsForm",
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapState({
      topics: state => state.baseConfiguration.topics,
      currentlyEditedBrand: state =>
        state.baseConfiguration.currentlyEditedBrand
    })
  },
  watch: {
    topics: {
      immediate: true,
      handler() {
        this.topics.forEach(topic => {
          if (!this.form[topic.localId]) {
            this.form[topic.localId] = null;
          }
        });
      }
    },
    currentlyEditedBrand: {
      immediate: true,
      handler() {
        this.form = { ...this.currentlyEditedBrand.topics };
      }
    }
  }
};
</script>
