<template>
  <div>
    <v-tabs v-model="tab" :key="items.length" class="bc-data-sources-tabs">
      <v-tabs-slider color="action"></v-tabs-slider>

      <v-tab v-for="item in items" :key="item.text" :disabled="isEditing">
        {{ item.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" :key="items.length + 'tabs'">
      <v-tab-item v-for="item in items" :key="item.text">
        <v-card class="pt-3" flat>
          <component :is="item.component" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import BrandList from "@/views/Project/components/Tabs/BrandList";
import TopicList from "@/views/Project/components/Tabs/TopicList";
import ExternalBrandsList from "@/views/Project/components/Tabs/ExternalBrandsList";

export default {
  name: "ConfigurationTabs",
  components: { ExternalBrandsList, TopicList, BrandList },
  data() {
    return {
      tab: null
    };
  },
  watch: {
    tab: {
      immediate: true,
      handler() {
        if (this.items[this.tab]) {
          this.setCurrentTab(this.items[this.tab]);
        }
      }
    },
    items: {
      deep: true,
      handler() {
        this.setCurrentTab(this.items[this.tab]);
      }
    }
  },
  computed: {
    ...mapGetters({
      items: "baseConfiguration/getAvailableTabs",
      isEditing: "baseConfiguration/isEditing"
    })
  },
  methods: {
    ...mapMutations({
      setCurrentTab: "baseConfiguration/setCurrentTab"
    })
  }
};
</script>

<style lang="scss">
.bc-data-sources-tabs {
  border-bottom: 1px solid rgba(150, 124, 124, 0.2);
}
</style>
