<template>
  <div>
    <section-heading
      :text="$t('PROJECTS.FORM.PROJECT_SETTINGS')"
      :sub-text="$t('PROJECTS.FORM.PROJECT_SETTINGS_SUB')"
    />
    <v-row>
      <v-col>
        <v-text-field
          :rules="[
            v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED'),
            rules.cantBeSpacesOnly,
            rules.minLength2
          ]"
          validate-on-blur
          v-model.trim="form.name"
          filled
          :label="$t('COMMON.NAME')"
          :disabled="isLoading"
        />
      </v-col>
    </v-row>
    <section-heading
      :sub-text="$t('PROJECTS.FORM.NAME_DATE_HEADING_SUB_TEXT')"
    />
    <v-row>
      <v-col cols="6">
        <v-menu-date-picker
          v-model="form.startDate"
          :rules="[v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED')]"
          :allowed-dates="allowedStartDates"
          :label="$t('COMMON.START_DATE')"
          :disabled="isLoading"
          :validate-on-blur="false"
        />
      </v-col>
      <v-col cols="6">
        <v-menu-date-picker
          v-model="form.endDate"
          :rules="[v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED')]"
          :label="$t('COMMON.END_DATE')"
          :allowed-dates="allowedEndDates"
          :disabled="isLoading || form.startDate === null"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VMenuDatePicker from "@/components/VMenuDatePicker";
import SectionHeading from "@/components/SectionHeading";
import { cantBeSpacesOnly } from "@/validators";
import { projectSettingsFactory } from "@/store/models/baseConfiguration";
import { mapState } from "vuex";
export default {
  name: "ProjectSettingsForm",
  components: { SectionHeading, VMenuDatePicker },
  data() {
    return {
      form: projectSettingsFactory(),
      isLoading: false,
      rules: {
        minLength2: value => {
          if (value) {
            return (
              value.trim().length > 1 ||
              this.$t("PROJECT.FORM.VALIDATION.MIN_LENGTH_2")
            );
          }
          return true;
        },
        cantBeSpacesOnly
      }
    };
  },
  computed: {
    ...mapState({
      currentDraftData: state => state.baseConfiguration.currentDraftData
    })
  },
  watch: {
    currentDraftData: {
      immediate: true,
      handler() {
        if (this.currentDraftData) {
          const { startDate = null, endDate = null } = this.currentDraftData;
          this.form.name = this.currentDraftData.name;
          this.form.startDate = startDate;
          this.form.endDate = endDate;
        }
      }
    }
  },
  methods: {
    allowedStartDates(val) {
      let isValueSameOrBeforeEndDate = true;
      const isValueSameOrBeforeToday = this.$moment(val).isSameOrBefore(
        this.$moment(),
        "day"
      );
      if (this.form.endDate !== null) {
        isValueSameOrBeforeEndDate = this.$moment(val).isSameOrBefore(
          this.form.endDate,
          "day"
        );
      }
      return isValueSameOrBeforeToday && isValueSameOrBeforeEndDate;
    },
    allowedEndDates(val) {
      const isValueSameOrBeforeToday = this.$moment(val).isSameOrBefore(
        this.$moment(),
        "day"
      );
      const isValueSameOrAfterStartDate = this.$moment(val).isSameOrAfter(
        this.form.startDate,
        "day"
      );
      return isValueSameOrBeforeToday && isValueSameOrAfterStartDate;
    }
  }
};
</script>
