<template>
  <div>
    <v-row v-if="currentlyEditedBrand === null">
      <v-col>
        <v-card flat class="text-center">
          <v-card-title class="justify-center">
            {{ $t("COMMON.NOTHING_SELECTED") }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t("PROJECTS.FORM.NOTHING_SELECTED_INFO") }}
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense v-else>
      <v-col>
        <brand-form />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BrandForm from "@/views/Project/components/Forms/BaseConfiguration/BrandForm";

export default {
  name: "BrandCard",
  components: { BrandForm },
  computed: {
    ...mapGetters({
      currentlyEditedBrand: "baseConfiguration/getCurrentlyEditedBrand"
    })
  }
};
</script>
