<template>
  <div>
    <v-row v-if="currentlyEditedTopic === null">
      <v-col>
        <v-card flat class="text-center">
          <v-card-title class="justify-center">
            {{ $t("COMMON.NOTHING_SELECTED") }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t("PROJECTS.FORM.NOTHING_SELECTED_INFO") }}
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense v-else>
      <v-col>
        <topic-form />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TopicForm from "@/views/Project/components/Forms/BaseConfiguration/TopicForm";

export default {
  name: "TopicCard",
  components: { TopicForm },
  computed: {
    ...mapState({
      currentlyEditedTopic: state =>
        state.baseConfiguration.currentlyEditedTopic
    })
  }
};
</script>
