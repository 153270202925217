<template>
  <v-card dark flat class="pa-3">
    <v-row dense>
      <v-col>
        <import-external-brands class="mt-3" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ImportExternalBrands from "@/views/Project/components/ImportExternalBrands";
export default {
  name: "ExternalBrandCard",
  components: { ImportExternalBrands },
  computed: {
    disableDelete() {
      return false;
    }
  }
};
</script>
