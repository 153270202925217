<template>
  <div>
    <v-row>
      <v-col cols="1" align-self="center">
        <v-icon large>mdi-facebook</v-icon>
      </v-col>
      <v-col>
        <v-text-field
          v-model="data.comments.fb"
          type="number"
          :label="$t('COMMON.COMMENTS')"
          filled
          hide-details
          @blur="
            data.comments.fb = data.comments.fb
              ? parseFloat(data.comments.fb)
              : data.comments.fb
          "
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="data.likes.fb"
          @blur="
            data.likes.fb = data.likes.fb
              ? parseFloat(data.likes.fb)
              : data.likes.fb
          "
          type="number"
          :label="$t('COMMON.LIKES')"
          filled
          hide-details
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="data.shares.fb"
          @blur="
            data.shares.fb = data.shares.fb
              ? parseFloat(data.shares.fb)
              : data.shares.fb
          "
          type="number"
          :label="$t('COMMON.SHARES')"
          filled
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1" align-self="center">
        <v-icon large>mdi-twitter</v-icon>
      </v-col>
      <v-col>
        <v-text-field
          v-model="data.comments.twitter"
          @blur="
            data.comments.twitter = data.comments.twitter
              ? parseFloat(data.comments.twitter)
              : data.comments.twitter
          "
          type="number"
          :label="$t('COMMON.COMMENTS')"
          filled
          hide-details
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="data.likes.twitter"
          @blur="
            data.likes.twitter = data.likes.twitter
              ? parseFloat(data.likes.twitter)
              : data.likes.twitter
          "
          type="number"
          :label="$t('COMMON.LIKES')"
          filled
          hide-details
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="data.shares.twitter"
          @blur="
            data.shares.twitter = data.shares.twitter
              ? parseFloat(data.shares.twitter)
              : data.shares.twitter
          "
          type="number"
          :label="$t('COMMON.SHARES')"
          filled
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1" align-self="center">
        <v-icon large>mdi-instagram</v-icon>
      </v-col>
      <v-col>
        <v-text-field
          v-model="data.comments.instagram"
          @blur="
            data.comments.instagram = data.comments.instagram
              ? parseFloat(data.comments.instagram)
              : data.comments.instagram
          "
          type="number"
          :label="$t('COMMON.COMMENTS')"
          filled
          hide-details
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="data.likes.instagram"
          @blur="
            data.likes.instagram = data.likes.instagram
              ? parseFloat(data.likes.instagram)
              : data.likes.instagram
          "
          type="number"
          :label="$t('COMMON.LIKES')"
          filled
          hide-details
        />
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col cols="1" align-self="center"> </v-col>
      <v-col>
        <v-text-field
          v-model="data.sum.video"
          @blur="
            data.sum.video = data.sum.video
              ? parseFloat(data.sum.video)
              : data.sum.video
          "
          type="number"
          :label="$t('COMMON.VIDEO')"
          filled
          hide-details
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="data.sum.photo"
          @blur="
            data.sum.photo = data.sum.photo
              ? parseFloat(data.sum.photo)
              : data.sum.photo
          "
          type="number"
          :label="$t('COMMON.PHOTO')"
          filled
          hide-details
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="data.sum.text"
          @blur="
            data.sum.text = data.sum.text
              ? parseFloat(data.sum.text)
              : data.sum.text
          "
          type="number"
          :label="$t('COMMON.TEXT')"
          filled
          hide-details
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { pulsarManualFactory } from "@/store/models/baseConfiguration";
import { mapMutations, mapState } from "vuex";

export default {
  name: "PulsarManual",
  data() {
    return {
      data: pulsarManualFactory()
    };
  },
  computed: {
    ...mapState({
      currentlyEditedBrand: state =>
        state.baseConfiguration.currentlyEditedBrand
    })
  },
  watch: {
    currentlyEditedBrand: {
      immediate: true,
      deep: true,
      handler() {
        if (this.currentlyEditedBrand.pulsar.manual) {
          this.data = JSON.parse(
            JSON.stringify(this.currentlyEditedBrand.pulsar.manual)
          );
        }
      }
    }
  },
  mounted() {
    this.$root.$on("updatePulsar", this.updatePulsar);
  },
  beforeDestroy() {
    this.$root.$off("updatePulsar", this.updatePulsar);
  },
  methods: {
    ...mapMutations({
      setPulsarManual: "baseConfiguration/setPulsarManual"
    }),
    hasFormChanged() {
      const factoryObject = pulsarManualFactory();
      let hasChanged = false;
      Object.keys(factoryObject).forEach(metric => {
        Object.keys(factoryObject[metric]).forEach(profile => {
          if (this.data[metric][profile]) {
            hasChanged = true;
          }
        });
      });

      return hasChanged;
    },
    clearData() {
      this.data = pulsarManualFactory();
    },
    updatePulsar() {
      this.setPulsarManual(this.data);
    }
  }
};
</script>
