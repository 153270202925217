<template>
  <div>
    <v-row v-if="!hideAddButton">
      <v-spacer />
      <v-col class="text-right">
        <v-btn
          outlined
          color="success"
          :disabled="disableAddNew"
          @click="$emit('add')"
        >
          {{ addButtonLabel }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="items.length < 1">
      <v-col>
        <v-card flat class="text-center">
          <v-card-title class="justify-center">
            {{ emptyLabelText }}
          </v-card-title>
          <v-card-subtitle>
            {{ emptyLabelSubText }}
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <div v-else>
      <v-row v-for="item in items" :key="item[itemKey]" dense>
        <v-col>
          <v-hover v-slot="{ hover }">
            <v-card
              flat
              outlined
              :dark="isItemDark(item) || (hover && !hideHover)"
            >
              <v-card-title>
                {{
                  item.name.length > 0
                    ? item.name
                    : $t("PROJECT.FORM.SAVE_FORM_TO_UPDATE_ITEM")
                }}
                <v-spacer />
                <v-btn
                  v-if="showEditIcon(item)"
                  icon
                  @click="$emit('edit', item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
export default {
  name: "ConfigurationList",
  props: {
    disableAddNew: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: "localId"
    },
    isItemDark: {
      type: Function,
      required: true
    },
    showEditIcon: {
      type: Function,
      required: true
    },
    hideAddButton: {
      type: Boolean,
      default: false
    },
    hideHover: {
      type: Boolean,
      default: false
    },
    emptyLabelText: {
      type: String,
      default: i18n.t("COMMON.NOTHING_ADDED")
    },
    emptyLabelSubText: {
      type: String,
      default: i18n.t("PROJECTS.FORM.ADD_A_NEW_ITEM")
    },
    addButtonLabel: {
      type: String,
      default: i18n.t("PROJECTS.FORM.ADD_A_NEW_ITEM")
    }
  }
};
</script>
