import uniq from "lodash.uniq";
import i18n from "@/i18n";

const profilePattern = /pulsarplatform\.com\/(.*)\/brandsets\/(\d+)\/brands\/(\d+)\/profiles\/(\d+)\//;

function getBrandIdForPlatform(platform, form) {
  const parsedPulsarURL = form[platform]?.url?.match(profilePattern);
  return parsedPulsarURL && parsedPulsarURL[3];
}

function getProfileIdForPlatform(platform, form) {
  const parsedPulsarURL = form[platform]?.url?.match(profilePattern);
  return parsedPulsarURL && parsedPulsarURL[4];
}

function arePulsarBrandsEqual(form) {
  const fbBrand = getBrandIdForPlatform("platformFacebook", form);
  const twitterBrand = getBrandIdForPlatform("platformTwitter", form);
  const instagramBrand = getBrandIdForPlatform("platformInstagram", form);
  const nonEmptyBrandIds = [fbBrand, twitterBrand, instagramBrand].filter(
    brandId => !!brandId
  );

  const uniqueBrandIds = uniq(nonEmptyBrandIds);
  return uniqueBrandIds.length <= 1;
}

function arePulsarProfileDifferent(value, form) {
  let url = value && value.match(profilePattern);
  let valueId = url && url[4];
  const fbBrand = getProfileIdForPlatform("platformFacebook", form);
  const twitterBrand = getProfileIdForPlatform("platformTwitter", form);
  const instagramBrand = getProfileIdForPlatform("platformInstagram", form);
  const nonEmptyProfilesIds = [fbBrand, twitterBrand, instagramBrand]
    .filter(profileID => profileID === valueId)
    .filter(profileId => !!profileId);
  const uniqueProfileIds = uniq(nonEmptyProfilesIds);
  return uniqueProfileIds.length === nonEmptyProfilesIds.length;
}

export function checkPulsarBrands(value, form) {
  if (!value) {
    return true;
  }
  const areAllBrandsEqual = arePulsarBrandsEqual(form);
  return (
    !!areAllBrandsEqual || i18n.t("PROJECT.FORM.VALIDATION.SAME_BRANDS_IDS")
  );
}

export function checkPulsarProfiles(value, form) {
  if (!value) {
    return true;
  }
  return (
    arePulsarProfileDifferent(value, form) ||
    i18n.t("PROJECT.FORM.VALIDATION.DIFFERENT_PROFILES_ID")
  );
}

export function urlMatchPattern(value) {
  if (!value) {
    return true;
  }

  return (
    !!value.match(profilePattern) ||
    i18n.t("PROJECT.FORM.VALIDATION.PATTERN_NOT_MATCHED")
  );
}
