<template>
  <div>
    <v-row dense>
      <v-col>
        <v-text-field
          v-model="form.name"
          :rules="[
            v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED'),
            rules.cantBeSpacesOnly
          ]"
          filled
          :label="$t('COMMON.BRAND_NAME')"
          :validate-on-blur="true"
          :disabled="isLoading"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-row class="mx-0 my-2">
          <v-img src="@/assets/images/brandwatch-white.png" max-width="125" />
        </v-row>
        <v-select
          v-model="form.tagId"
          ref="select"
          item-text="name"
          item-value="id"
          filled
          :rules="[v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED')]"
          :disabled="tagSelectDisabled"
          :readonly="!brandwatchQueryIds"
          :loading="tagsLoading"
          :items="tags"
          :label="$t('COMMON.BRAND_FILTER_TAG')"
          :menu-props="{ bottom: true, offsetY: true }"
          :validate-on-blur="true"
          :error-messages="queryStringErrorMessage"
          @input="$refs.select.blur()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mx-4 pa-0">
        <v-img src="@/assets/images/similarweb-white.png" max-width="113" />
      </v-col>
      <v-col cols="9">
        <v-text-field
          v-model="form.sites[0].url"
          :validate-on-blur="true"
          :disabled="isLoading"
          filled
          :label="$t('PROJECT.FORM.URL_ADDRESS')"
        />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="form.sites[0].geo"
          :disabled="isLoading"
          item-text="country"
          item-value="alpha2"
          :items="countries"
          filled
          :label="$t('COMMON.REGION')"
          :menu-props="{ bottom: true, offsetY: true }"
          :validate-on-blur="true"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-img src="@/assets/images/pulsar-white.png" max-width="110" />
      </v-col>
      <v-col>
        <v-tabs
          v-model="pulsarTab"
          class="bc-data-sources-tabs"
          @change="handlePulsarTabChange"
        >
          <v-tabs-slider color="action"></v-tabs-slider>

          <v-tab>
            Pulsar api
          </v-tab>
          <v-tab>
            Set manually
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentPulsarTab" dark>
          <v-tab-item>
            <v-row class="mt-4">
              <pulsar-a-p-i ref="pulsarApi" :region="form.sites[0].geo" />
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <pulsar-manual ref="pulsarManual" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row v-if="isBavOfflineSelected">
      <v-col cols="12" class="mx-4 pa-0">
        <!--        <v-img-->
        <!--          src="@/assets/images/brandwatch-logo-horizontal.png"-->
        <!--          max-width="113"-->
        <!--        />-->
        <span class="font-weight-bold">BAV OFFLINE</span>
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="form.bavOffline"
          :search-input.sync="bavOfflineSearch"
          :validate-on-blur="true"
          :items="mappedOfflineItems"
          :disabled="isLoading"
          :loading="isBavOfflineLoading || isLoading"
          :placeholder="$t('PROJECTS.FORM.TYPE_TO_FILL_DATA')"
          :label="$t('PROJECTS.FORM.SELECTED_BRAND')"
          :rules="[v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED')]"
          no-filter
          item-value="brandHash"
          filled
          return-object
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { brandDataFactory } from "@/store/models/baseConfiguration";
import { cantBeSpacesOnly } from "@/validators";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import countries from "@/../node_modules/iso-3166-1/dist/iso-3166.js";
import { debounce } from "debounce";
import PulsarManual from "@/views/Project/components/Tabs/PulsarManual";
import PulsarAPI from "@/views/Project/components/Tabs/PulsarAPI";

export default {
  name: "DataSettingsForm",
  components: { PulsarAPI, PulsarManual },
  data() {
    return {
      form: brandDataFactory(),
      pulsarTab: 0,
      currentPulsarTab: 0,
      rules: {
        cantBeSpacesOnly,
        required: v => !!v || this.$t("VALIDATION.COMMON.FIELD_REQUIRED")
      },
      tags: [],
      offlineItems: [],
      bavOfflineSearch: null,
      isBavOfflineLoading: false,
      countries,
      isLoading: false,
      tagsLoading: false
    };
  },
  computed: {
    ...mapGetters({
      currentlyEditedBrand: "baseConfiguration/getCurrentlyEditedBrand",
      selectedDataSources: "baseConfiguration/getSelectedDataSources",
      brandwatchQueryIds: "baseConfiguration/getBrandwatchQueryIds"
    }),
    ...mapState({
      currentDraftData: state => state.baseConfiguration.currentDraftData
    }),
    isBavOfflineSelected() {
      return this.selectedDataSources.includes("BAV_OFFLINE");
    },
    tagSelectDisabled() {
      return this.isLoading || this.tagsLoading;
    },
    queryStringErrorMessage() {
      return !this.brandwatchQueryIds
        ? this.$t("PROJECTS.FORM.MUST_SET_BW_QUERY_STRING_FIRST")
        : "";
    },
    mappedOfflineItems() {
      return this.offlineItems.map(item => {
        return {
          ...item,
          text: `${item.name} (${this.$moment(item.dateFrom).format(
            "MM.YYYY"
          )}-${this.$moment(item.dateTo).format("MM.YYYY")}) - ${item.region}`
        };
      });
    }
  },
  watch: {
    currentlyEditedBrand: {
      immediate: true,
      handler() {
        this.form = { ...this.currentlyEditedBrand };
        this.currentPulsarTab = this.currentlyEditedBrand.pulsar.currentTab;
        this.pulsarTab = this.currentlyEditedBrand.pulsar.currentTab;

        if (this.form.bavOffline !== null) {
          this.offlineItems.push(this.form.bavOffline);
        }
      }
    },
    brandwatchQueryIds: {
      immediate: true,
      deep: true,
      handler() {
        if (this.brandwatchQueryIds) {
          this.handleGetTags();
        } else {
          this.tags = [];
        }
      }
    },
    bavOfflineSearch(val) {
      if (!val) {
        return;
      }
      if (this.form.bavOffline && val === this.form.bavOffline.text) {
        return;
      }

      this.isBavOfflineLoading = true;
      this.querySearchBrand();
    }
  },
  mounted() {
    this.countries.unshift({
      alpha2: null,
      country: "Global"
    });
    this.$root.$on("updatePulsar", this.updatePulsarTab);
  },
  beforeDestroy() {
    this.$root.$off("updatePulsar", this.updatePulsarTab);
  },
  methods: {
    ...mapMutations({
      setPulsarTab: "baseConfiguration/setPulsarTab"
    }),
    ...mapActions({
      getTags: "api/project/getTags",
      searchBrand: "api/draft/searchBrand"
    }),
    updatePulsarTab() {
      this.setPulsarTab({
        brandId: this.currentlyEditedBrand.localId,
        tab: this.currentPulsarTab
      });
    },
    handleGetTags() {
      const { projectId, brandwatchId } = this.brandwatchQueryIds;
      this.tagsLoading = true;
      this.getTags({
        brandwatchProjectId: projectId,
        brandwatchQueryId: brandwatchId,
        marketId: this.currentDraftData._market
      })
        .then(({ data }) => {
          this.tags = data;
        })
        .catch(error => {
          this.$notifier.notify({ message: error, color: "error" });
        })
        .finally(() => {
          this.tagsLoading = false;
        });
    },
    querySearchBrand: debounce(function() {
      this.searchBrand(this.bavOfflineSearch)
        .then(response => {
          this.offlineItems = response.data;
        })
        .finally(() => {
          this.isBavOfflineLoading = false;
        });
    }, 500),
    handlePulsarTabChange() {
      if (this.hasPulsarFormChanged()) {
        this.$modals.confirmation({
          action: this.changePulsarTab,
          cancelAction: () => {
            this.pulsarTab = this.currentPulsarTab;
          },
          content: this.$t("PROJECT.PULSAR_TAB_CHANGE_REMOVES_DATA")
        });
      } else {
        this.changePulsarTab();
      }
    },
    changePulsarTab() {
      const refs = [this.$refs.pulsarApi, this.$refs.pulsarManual];
      refs[this.currentPulsarTab]?.clearData();
      this.currentPulsarTab = this.pulsarTab;
    },
    hasPulsarFormChanged() {
      const refs = [this.$refs.pulsarApi, this.$refs.pulsarManual];

      return refs[this.currentPulsarTab]?.hasFormChanged();
    }
  }
};
</script>
