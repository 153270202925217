var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col col-12"},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help-circle-outline")]),_c('small',[_vm._v(" "+_vm._s(_vm.$t("PROJECT.FORM.EXAMPLE_URL"))+" "),_c('p',{staticStyle:{"word-wrap":"anywhere"}},[_vm._v(" https://yr.pulsarplatform.com/admin/owned_channels/brandsets/4167/brands/24298/profiles/46394/audience/public/1607922000/1610513999 ")])])],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"clearable":"","filled":"","prepend-icon":"mdi-facebook","disabled":_vm.isLoading,"error-messages":_vm.profileErrorMessage,"label":_vm.$t("PROJECT.FORM.PULSAR_URL"),"rules":[
          _vm.urlMatchPattern,
          _vm.handleCheckPulsarProfiles,
          _vm.handleCheckPulsarBrands
        ],"validate-on-blur":true},on:{"input":function($event){_vm.profileErrorMessage = ''}},model:{value:(_vm.data.platformFacebook.url),callback:function ($$v) {_vm.$set(_vm.data.platformFacebook, "url", $$v)},expression:"data.platformFacebook.url"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"filled":"","item-value":"mode","disabled":_vm.isLoading || !_vm.data.platformFacebook.url || _vm.region === null,"items":_vm.regions,"label":_vm.$t('COMMON.REGION'),"menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.data.platformFacebook.mode),callback:function ($$v) {_vm.$set(_vm.data.platformFacebook, "mode", $$v)},expression:"data.platformFacebook.mode"}})],1)],1),_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"clearable":"","filled":"","prepend-icon":"mdi-twitter","disabled":_vm.isLoading,"error-messages":_vm.profileErrorMessage,"label":_vm.$t("PROJECT.FORM.PULSAR_URL"),"rules":[
          _vm.urlMatchPattern,
          _vm.handleCheckPulsarProfiles,
          _vm.handleCheckPulsarBrands
        ],"validate-on-blur":true},on:{"input":function($event){_vm.profileErrorMessage = ''}},model:{value:(_vm.data.platformTwitter.url),callback:function ($$v) {_vm.$set(_vm.data.platformTwitter, "url", $$v)},expression:"data.platformTwitter.url"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"filled":"","item-text":"text","item-value":"mode","disabled":_vm.isLoading || !_vm.data.platformTwitter.url || _vm.region === null,"items":_vm.regions,"label":_vm.$t('COMMON.REGION'),"menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.data.platformTwitter.mode),callback:function ($$v) {_vm.$set(_vm.data.platformTwitter, "mode", $$v)},expression:"data.platformTwitter.mode"}})],1)],1),_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"clearable":"","filled":"","prepend-icon":"mdi-instagram","disabled":_vm.isLoading,"error-messages":_vm.profileErrorMessage,"label":_vm.$t("PROJECT.FORM.PULSAR_URL"),"rules":[
          _vm.urlMatchPattern,
          _vm.handleCheckPulsarProfiles,
          _vm.handleCheckPulsarBrands
        ],"validate-on-blur":true},on:{"input":function($event){_vm.profileErrorMessage = ''}},model:{value:(_vm.data.platformInstagram.url),callback:function ($$v) {_vm.$set(_vm.data.platformInstagram, "url", $$v)},expression:"data.platformInstagram.url"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"3"}},[_c('v-select',{attrs:{"filled":"","item-text":"text","item-value":"mode","disabled":_vm.isLoading || !_vm.data.platformInstagram.url || _vm.region === null,"items":_vm.regions,"label":_vm.$t('COMMON.REGION'),"menu-props":{ bottom: true, offsetY: true }},model:{value:(_vm.data.platformInstagram.mode),callback:function ($$v) {_vm.$set(_vm.data.platformInstagram, "mode", $$v)},expression:"data.platformInstagram.mode"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }