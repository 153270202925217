<template>
  <configuration-list
    :items="brands"
    :disable-add-new="disableAddNewBrandButton"
    :show-edit-icon="showEditIcon"
    :is-item-dark="isItemDark"
    :add-button-label="$t('PROJECTS.FORM.ADD_NEW_BRAND')"
    @add="handleAddBrand"
    @edit="handleEditBrand"
  />
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { brandDataFactory } from "@/store/models/baseConfiguration";
import ConfigurationList from "@/views/Project/components/ConfigurationList";

export default {
  name: "BrandList",
  components: { ConfigurationList },
  computed: {
    ...mapState({
      brandFormRef: state => state.baseConfiguration.brandFormRef,
      brandwatchQueryString: state =>
        state.baseConfiguration.brandwatchQueryString
    }),
    ...mapGetters({
      brands: "baseConfiguration/getBrands",
      currentlyEditedBrand: "baseConfiguration/getCurrentlyEditedBrand"
    }),
    disableAddNewBrandButton() {
      return !this.validateAddBrandAction() || !this.brandwatchQueryString;
    }
  },
  filters: {
    brandName(name) {
      return name.length > 0
        ? name
        : "Please enter brand name in the right section.";
    }
  },
  beforeDestroy() {
    this.setCurrentlyEditedBrand(null);
  },
  methods: {
    ...mapMutations({
      addBrand: "baseConfiguration/addBrand",
      setCurrentlyEditedBrand: "baseConfiguration/setCurrentlyEditedBrand"
    }),
    showEditIcon() {
      return this.currentlyEditedBrand === null;
    },
    isItemDark(brand) {
      return (
        this.currentlyEditedBrand &&
        this.currentlyEditedBrand.localId === brand.localId
      );
    },
    validateAddBrandAction() {
      return this.currentlyEditedBrand === null;
    },
    handleAddBrand() {
      if (this.validateAddBrandAction()) {
        const brand = brandDataFactory();
        this.addBrand(brand);
        this.setCurrentlyEditedBrand(brand);
      }
    },
    handleEditBrand(brand) {
      if (this.brandFormRef) {
        if (this.brandFormRef.validate()) {
          this.setCurrentlyEditedBrand(brand);
        }
      } else {
        this.setCurrentlyEditedBrand(brand);
      }
    }
  }
};
</script>
