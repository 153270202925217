<template>
  <v-container fluid style="max-width: 1785px">
    <page-card :disabled="isLoading" :is-loading="isLoading">
      <base-configuration v-if="!isLoading" />
    </page-card>
  </v-container>
</template>

<script>
import PageCard from "@/components/PageCard";
import { mapActions, mapMutations } from "vuex";
import BaseConfiguration from "@/views/Project/components/BaseConfiguration";

export default {
  name: "ProjectsCreator",
  components: { BaseConfiguration, PageCard },
  data() {
    return {
      isLoading: true,
      confirmPageLeave: true
    };
  },
  computed: {
    isEditing() {
      return true;
    }
  },
  beforeRouteLeave(to, from, next) {
    const allowRedirect =
      to.query.allowRedirect && to.query.allowRedirect === "yes";
    if (to.name === "Login") {
      next();
    }
    if (allowRedirect) {
      next();
    }
    if (this.$route.params.draft && !allowRedirect) {
      this.$modals.confirmation({
        title: this.$t("PROJECTS.FORM.LEAVE_PAGE_TITLE"),
        content: this.$t("PROJECTS.FORM.LEAVE_PAGE_CONTENT"),
        action: () => next()
      });
    } else {
      next();
    }
  },
  created() {
    if (this.$route.params.draft !== "draft") {
      this.getProject(this.$route.params.projectId)
        .then(({ data }) => {
          this.redirectByProjectStatus(data.state.status);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.getDraft(this.$route.params.projectId).finally(() => {
        this.isLoading = false;
      });
    }
  },
  beforeDestroy() {
    this.clearState();
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.name === "ProjectsCreator") {
          this.setProject(null);
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getProject: "api/project/getProject",
      getDraft: "baseConfiguration/getDraft"
    }),
    ...mapMutations({
      setProject: "api/project/setProject",
      clearState: "baseConfiguration/clearState"
    }),
    preventPageChange(event) {
      if (!this.isEditing) return;
      event.preventDefault();
      event.returnValue = "";
    },
    redirectByProjectStatus(status) {
      const resolver = {
        processing: "ProcessingData",
        data_preview: "DataPreview",
        language_audit: "LanguageAudit",
        data_from_brandwatch: "DataFromBW",
        done: "Dashboard"
      };
      const name = resolver[status];

      if (name !== undefined) {
        this.$router.push({
          name,
          params: { projectId: this.$route.params.projectId }
        });
      }
    }
  }
};
</script>
