<template>
  <v-form ref="form">
    <v-row>
      <v-col :cols="6">
        <project-settings-form ref="projectSettingsForm" />
        <v-row dense>
          <v-col>
            <project-data-sources />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <configuration-tabs />
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="6">
        <v-card class="pa-2" min-height="850" outlined>
          <component v-if="currentTab" :is="currentTab.tabComponent" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer />
      <v-col class="text-right">
        <v-btn
          outlined
          color="action"
          class="mr-2"
          :disabled="isLoading || isDraftLoading || isItemEdited"
          :loading="isDraftLoading"
          @click="handleSaveDraft"
          >{{ $t("COMMON.SAVE_AND_CLOSE") }}</v-btn
        >
        <v-btn
          outlined
          color="action"
          :disabled="isLoading || isProcessingLoading || isItemEdited"
          :loading="isProcessingLoading"
          @click="handleSubmit"
          >{{ $t("COMMON.PROCESS_DATA") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import ProjectSettingsForm from "@/views/Project/components/Forms/BaseConfiguration/ProjectSettingsForm";
import ProjectDataSources from "@/views/Project/components/Forms/BaseConfiguration/ProjectDataSources";
import ConfigurationTabs from "@/views/Project/components/ConfigurationTabs";
import BrandCard from "@/views/Project/components/Tabs/components/BrandCard";
import ExternalBrandCard from "@/views/Project/components/Tabs/components/ExternalBrandCard";
import TopicCard from "@/views/Project/components/Tabs/components/TopicCard";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "BaseConfiguration",
  components: {
    ConfigurationTabs,
    ProjectDataSources,
    ProjectSettingsForm,
    BrandCard,
    ExternalBrandCard,
    TopicCard
  },
  data() {
    return {
      isLoading: false,
      isDraftLoading: false,
      isProcessingLoading: false
    };
  },
  computed: {
    ...mapState({
      topics: state => state.baseConfiguration.topics,
      brands: state => state.baseConfiguration.brands,
      selectedDataSources: state => state.baseConfiguration.selectedDataSources,
      currentlyEditedBrand: state =>
        state.baseConfiguration.currentlyEditedBrand,
      currentlyEditedTopic: state =>
        state.baseConfiguration.currentlyEditedTopic
    }),
    ...mapGetters({
      currentTab: "baseConfiguration/getCurrentTab"
    }),
    isItemEdited() {
      return (
        this.currentlyEditedBrand !== null || this.currentlyEditedTopic !== null
      );
    }
  },
  beforeDestroy() {
    this.clearState();
  },
  methods: {
    ...mapMutations({
      clearState: "baseConfiguration/clearState"
    }),
    ...mapActions({
      saveDraft: "baseConfiguration/saveDraft",
      saveProject: "baseConfiguration/saveProject"
    }),
    async handleSubmit() {
      await this.forceSaveDraft();
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.validateConfiguration()) {
        return;
      }

      const projectSettingsForm = this.$refs.projectSettingsForm.form;
      this.isLoading = true;
      this.isProcessingLoading = true;
      this.saveProject({
        projectSettingsForm,
        draftId: this.$route.params.projectId
      })
        .then(() => {
          this.$router.push({
            name: "ProcessingData",
            projectId: this.$route.params.projectId,
            query: {
              allowRedirect: "yes"
            }
          });
        })
        .catch(error => {
          this.$notifier.notify({ message: error, color: "error" });
        })
        .finally(() => {
          this.isLoading = false;
          this.isProcessingLoading = false;
        });
    },
    forceSaveDraft() {
      const projectSettings = this.$refs.projectSettingsForm.form;
      return this.saveDraft({
        projectSettings,
        draftId: this.$route.params.projectId
      });
    },
    handleSaveDraft() {
      const projectSettings = this.$refs.projectSettingsForm.form;
      this.isLoading = true;
      this.isDraftLoading = true;
      this.saveDraft({ projectSettings, draftId: this.$route.params.projectId })
        .then(() => {
          this.clearState();
          this.$router.push({
            name: "Home",
            query: {
              allowRedirect: "yes"
            }
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.isDraftLoading = false;
        });
    },
    validateConfiguration() {
      let hasEmptyTopics = false;
      if (this.brands.length < 1) {
        this.$modals.validation({
          title: this.$t("PROJECT.VALIDATION.PROCESS_DATA_ERROR_TITLE"),
          content: this.$t("PROJECT.VALIDATION.BRANDS_CANNOT_BE_EMPTY")
        });
        return false;
      }
      if (this.selectedDataSources.includes("BAV_OFFLINE")) {
        if (this.brands.filter(brand => brand.bavOffline === null).length > 0) {
          this.$modals.validation({
            title: this.$t("PROJECT.VALIDATION.PROCESS_DATA_ERROR_TITLE"),
            content: this.$t("PROJECT.VALIDATION.BAV_OFFLINE_CANNOT_BE_EMPTY")
          });
          return false;
        }
      }
      if (this.selectedDataSources.includes("TOPICS_FROM_LANGUAGE_AUDIT")) {
        if (this.topics.length < 1) {
          this.$modals.validation({
            title: this.$t("PROJECT.VALIDATION.PROCESS_DATA_ERROR_TITLE"),
            content: this.$t("PROJECT.VALIDATION.TOPICS_CANNOT_BE_EMPTY")
          });
          return false;
        }
        this.brands.forEach(brand => {
          if (
            !brand.topics ||
            Object.keys(brand.topics).length < 1 ||
            Object.keys(brand.topics).length < this.topics.length
          ) {
            hasEmptyTopics = true;
          } else {
            Object.values(brand.topics).forEach(value => {
              if (value !== 0 && !value) {
                hasEmptyTopics = true;
              }
            });
          }
        });

        if (hasEmptyTopics) {
          this.$modals.validation({
            title: this.$t("PROJECT.VALIDATION.PROCESS_DATA_ERROR_TITLE"),
            content: this.$t(
              "PROJECT.VALIDATION.BRANDS_MUST_HAVE_FILLED_TOPICS"
            )
          });

          return false;
        }
      }

      return true;
    }
  }
};
</script>
