var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section-heading',{attrs:{"sub-text":_vm.$t('PROJECTS.FORM.DATA_SOURCES')}}),_vm._l((_vm.dataSources),function(text,source){return _c('v-checkbox',{key:source,staticClass:"d-inline-block mr-4",attrs:{"label":text,"value":source,"disabled":_vm.isEditing,"color":"primary"},on:{"change":_vm.handleSelect},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('v-img',{attrs:{"src":require("@/assets/images/brandwatch-logo-horizontal.png"),"max-width":"125"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":[
          function (v) { return !!v || _vm.$t('VALIDATION.COMMON.FIELD_REQUIRED'); },
          _vm.rules.brandwatchQueryString
        ],"disabled":_vm.isLoading,"filled":"","label":_vm.$t('PROJECTS.FORM.BRANDWATCH_QUERY'),"persistent-hint":"","validate-on-blur":"","hint":("" + (_vm.$t('PROJECT.FORM.BRANDWATCH_QUERY_INFO')) + (_vm.$t(
            'COMMON.EXAMPLE'
          )) + ": app.brandwatch.com/project/1998283878/explore/2000280218")},on:{"blur":_vm.handleSaveBrandwatchQueryString},scopedSlots:_vm._u([{key:"message",fn:function(ref){
          var message = ref.message;
          var key = ref.key;
return [_c('div',{key:key,staticStyle:{"line-height":"18px"},domProps:{"innerHTML":_vm._s(message)}})]}}]),model:{value:(_vm.brandwatchQueryString),callback:function ($$v) {_vm.brandwatchQueryString=$$v},expression:"brandwatchQueryString"}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }