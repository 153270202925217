<template>
  <v-form ref="form" @submit.prevent="">
    <v-row>
      <v-col>
        <v-card flat dark class="pa-3">
          <v-text-field
            v-model="form.name"
            :label="$t('FORM.INPUT.TOPIC_NAME')"
            :rules="[
              v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED'),
              rules.cantBeSpacesOnly
            ]"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer />
      <v-col class="text-right">
        <v-btn
          class="mr-2"
          color="error"
          outlined
          :disabled="disableDelete"
          @click="handleDelete"
        >
          {{ $t("COMMON.DELETE") }}
        </v-btn>
        <v-btn class="mr-2" outlined @click="handleCancel">{{
          $t("COMMON.CANCEL")
        }}</v-btn>
        <v-btn outlined @click="handleSubmit">{{ saveActionButtonText }}</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { topicDataFactory } from "@/store/models/baseConfiguration";
import { cantBeSpacesOnly } from "@/validators";
export default {
  name: "TopicForm",
  data() {
    return {
      form: topicDataFactory(),
      rules: {
        cantBeSpacesOnly
      }
    };
  },
  computed: {
    ...mapState({
      currentlyEditedTopic: state =>
        state.baseConfiguration.currentlyEditedTopic
    }),
    disableDelete() {
      return !this.currentlyEditedTopic.wasSaved;
    },
    saveActionButtonText() {
      return this.currentlyEditedTopic.wasSaved
        ? this.$t("COMMON.UPDATE")
        : this.$t("COMMON.ADD");
    }
  },
  watch: {
    currentlyEditedTopic: {
      immediate: true,
      handler() {
        this.form = { ...this.currentlyEditedTopic };
      }
    }
  },
  beforeDestroy() {
    this.handleCancel();
  },
  methods: {
    ...mapMutations({
      updateTopic: "baseConfiguration/updateTopic",
      removeTopic: "baseConfiguration/removeTopic",
      setCurrentlyEditedTopic: "baseConfiguration/setCurrentlyEditedTopic"
    }),
    handleSubmit() {
      if (this.$refs.form.validate()) {
        this.updateTopic({
          ...this.form,
          wasSaved: true
        });

        this.clearCurrentlyEditedTopic();
      }
    },
    handleCancel() {
      if (this.currentlyEditedTopic && !this.currentlyEditedTopic.wasSaved) {
        this.removeTopic(this.currentlyEditedTopic);
      }

      this.clearCurrentlyEditedTopic();
    },
    handleDelete() {
      this.removeTopic(this.currentlyEditedTopic);
      this.clearCurrentlyEditedTopic();
    },
    clearCurrentlyEditedTopic() {
      this.setCurrentlyEditedTopic(null);
    }
  }
};
</script>
