<template>
  <div class="col col-12">
    <v-row class="mx-0">
      <v-col cols="12">
        <v-icon small>mdi-help-circle-outline</v-icon>
        <small>
          {{ $t("PROJECT.FORM.EXAMPLE_URL") }}
          <p style="word-wrap:anywhere;">
            https://yr.pulsarplatform.com/admin/owned_channels/brandsets/4167/brands/24298/profiles/46394/audience/public/1607922000/1610513999
          </p>
        </small>
      </v-col>
      <v-col cols="9" class="pt-0 pb-0">
        <v-text-field
          clearable
          filled
          prepend-icon="mdi-facebook"
          v-model="data.platformFacebook.url"
          :disabled="isLoading"
          :error-messages="profileErrorMessage"
          :label="$t(`PROJECT.FORM.PULSAR_URL`)"
          :rules="[
            urlMatchPattern,
            handleCheckPulsarProfiles,
            handleCheckPulsarBrands
          ]"
          :validate-on-blur="true"
          @input="profileErrorMessage = ''"
        />
      </v-col>
      <v-col cols="3" class="pt-0 pb-0">
        <v-select
          filled
          item-value="mode"
          v-model="data.platformFacebook.mode"
          :disabled="isLoading || !data.platformFacebook.url || region === null"
          :items="regions"
          :label="$t('COMMON.REGION')"
          :menu-props="{ bottom: true, offsetY: true }"
        />
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col cols="9" class="pt-0 pb-0">
        <v-text-field
          clearable
          filled
          prepend-icon="mdi-twitter"
          v-model="data.platformTwitter.url"
          :disabled="isLoading"
          :error-messages="profileErrorMessage"
          :label="$t(`PROJECT.FORM.PULSAR_URL`)"
          :rules="[
            urlMatchPattern,
            handleCheckPulsarProfiles,
            handleCheckPulsarBrands
          ]"
          :validate-on-blur="true"
          @input="profileErrorMessage = ''"
        />
      </v-col>
      <v-col cols="3" class="pt-0 pb-0">
        <v-select
          filled
          item-text="text"
          item-value="mode"
          v-model="data.platformTwitter.mode"
          :disabled="isLoading || !data.platformTwitter.url || region === null"
          :items="regions"
          :label="$t('COMMON.REGION')"
          :menu-props="{ bottom: true, offsetY: true }"
        />
      </v-col>
    </v-row>
    <v-row class="mx-0">
      <v-col cols="9" class="pt-0 pb-0">
        <v-text-field
          clearable
          filled
          prepend-icon="mdi-instagram"
          v-model="data.platformInstagram.url"
          :disabled="isLoading"
          :error-messages="profileErrorMessage"
          :label="$t(`PROJECT.FORM.PULSAR_URL`)"
          :rules="[
            urlMatchPattern,
            handleCheckPulsarProfiles,
            handleCheckPulsarBrands
          ]"
          :validate-on-blur="true"
          @input="profileErrorMessage = ''"
        />
      </v-col>
      <v-col cols="3" class="pt-0 pb-0">
        <v-select
          filled
          item-text="text"
          item-value="mode"
          v-model="data.platformInstagram.mode"
          :disabled="
            isLoading || !data.platformInstagram.url || region === null
          "
          :items="regions"
          :label="$t('COMMON.REGION')"
          :menu-props="{ bottom: true, offsetY: true }"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { pulsarApiFactory } from "@/store/models/baseConfiguration";
import {
  checkPulsarBrands,
  checkPulsarProfiles,
  urlMatchPattern
} from "@/helpers/pulsarHelper";
import { mapMutations, mapState } from "vuex";

export default {
  name: "PulsarAPI",
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    region: {
      required: true
    }
  },
  data() {
    return {
      data: pulsarApiFactory(),
      regions: [
        { text: "Regional", mode: "regional" },
        { text: "Global", mode: "global" }
      ],
      profileErrorMessage: ""
    };
  },
  computed: {
    ...mapState({
      currentlyEditedBrand: state =>
        state.baseConfiguration.currentlyEditedBrand
    })
  },
  watch: {
    currentlyEditedBrand: {
      immediate: true,
      deep: true,
      handler() {
        if (this.currentlyEditedBrand.pulsar.api) {
          this.data = JSON.parse(
            JSON.stringify(this.currentlyEditedBrand.pulsar.api)
          );
        }
      }
    }
  },
  mounted() {
    this.$root.$on("updatePulsar", this.updatePulsar);
  },
  beforeDestroy() {
    this.$root.$off("updatePulsar", this.updatePulsar);
  },
  methods: {
    ...mapMutations({
      setPulsarApi: "baseConfiguration/setPulsarApi"
    }),
    handleCheckPulsarProfiles(value) {
      return checkPulsarProfiles(value, this.data);
    },
    handleCheckPulsarBrands(value) {
      return checkPulsarBrands(value, this.data);
    },
    urlMatchPattern,
    hasFormChanged() {
      const factoryObject = pulsarApiFactory();
      let hasChanged = false;
      Object.keys(factoryObject).forEach(factoryKey => {
        if (this.data[factoryKey].url) {
          hasChanged = true;
        }
      });

      return hasChanged;
    },
    clearData() {
      this.data = pulsarApiFactory();
    },
    updatePulsar() {
      this.setPulsarApi(this.data);
    }
  }
};
</script>
